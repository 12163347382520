import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './home-page-about-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile ${styles.columnsReversed}`}
      >
        <div className="column is-6 is-offset-1">
          <h3 className="title is-3 has-text-info">
            Become a part of the #TrustaffTraveler family
          </h3>
          <div className="content">
            <p className="content">
              Founded in 2002, Trustaff is one of the largest and most
              well-respected healthcare staffing companies in the industry.
            </p>
            <ul>
              <li>Privately owned and operated</li>
              <li>Joint Commission certified</li>
              <li>Outstanding personal service</li>
              <li>Patient care comes first</li>
            </ul>
            <p className="content">
              We are proud to represent some of the most skilled healthcare
              professionals in the country and work diligently to secure them
              the best travel nurse assignments at outstanding facilities.
            </p>
          </div>
          <Link to="/about-us/" className="button is-primary">
            About Trustaff
          </Link>
        </div>
        <div className={`column is-5 ${styles.noSidePaddingMobile}`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Trustaff team photo"
          />
        </div>
      </div>
    </div>
  );
};
