import React from 'react';
import ApplySection from './apply-section/apply-section';
import HomePageSlider from './slider/home-page-slider';

export default ({ imgFluid }) => {
  return (
    <>
      <section className="section">
        <HomePageSlider />
      </section>

      <ApplySection fluid={imgFluid} />
    </>
  );
};
