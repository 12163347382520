import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import JobListItem from '../jobs/jobs-list/jobs-list-item';
import styles from './home-page-jobs-list.module.scss';
import { useJobsApi } from '../../services/jobs-service';

export default ({ data }) => {
  const { fetchJobs } = useJobsApi();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const filters = { limit: 6 };
    fetchJobs(filters).then(({ data }) => {
      setJobs(data);
    });
  }, []);

  return (
    <>
      <div className="columns">
        <div className="column is-9 container has-text-centered">
          <h3 className="title is-3 has-text-info">
            <Link className={`${styles.link}`} to="/jobs/">
              Today’s best travel nurse jobs
            </Link>
          </h3>
          <p>
            Whether you’ve got a specific location in mind or you’re ready to go
            whereever the adventure takes you next, Trustaff has hundreds of
            premier jobs in all 50 states for every specialty and modality.
          </p>
        </div>
      </div>
      <div className={styles.jobsWrapper}>
        <div className="columns">
          {jobs.slice(0, 3).map((job, index) => (
            <div
              className={`column is-4 ${index === 2 ? 'is-hidden-mobile' : ''}`}
              key={job.id}
            >
              <JobListItem
                jobBoxClass={styles.jobBoxOverride}
                queryData={data}
                {...job}
              />
            </div>
          ))}
        </div>
        <div className="columns is-hidden-mobile">
          {jobs.slice(3).map(job => (
            <div className="column is-4" key={job.id}>
              <JobListItem
                jobBoxClass={styles.jobBoxOverride}
                queryData={data}
                {...job}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="columns">
        <div className="column is-8 container has-text-centered">
          <Link
            to="/jobs/"
            className={`button is-info ${styles.searchJobsBtn}`}
          >
            Search jobs now
          </Link>
        </div>
      </div>
    </>
  );
};
