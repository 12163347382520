import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import JobApplicationForm from '../../../job-application-form/job-application-form';
import formLocations from '../../../job-application-form/form-locations';

import styles from './apply-section.module.scss';

const ApplySection = ({ fluid }) => {
  return (
    <section className={`section ${styles.greyBackground}`}>
      <div className={`columns"`}>
        <div className={`column is-12 is-10-desktop is-offset-1-desktop`}>
          <div className="columns">
            <div className={`column is-6`}>
              <div className="container content">
                <h3 className="title is-3 has-text-info">
                  It's time to take your career (and your paycheck) to the next
                  level
                </h3>
                <p>
                  Find out why our travelers trust Trustaff to find them the
                  best travel nurse assignments again and again. Sign up, search
                  jobs, and speak to a recruiter with absolutely no obligation.
                </p>
              </div>

              <JobApplicationForm
                job={null}
                formLocation={formLocations.HOME_PAGE}
              />

              <div className={`${styles.CCPA}`}>
                <Link to="/privacy-notice/" className={`is-size-7`}>
                  CCPA Privacy Notice for Job Applicants
                </Link>
              </div>
            </div>

            <div className={`column is-5 is-offset-1 ${styles.imageColumn}`}>
              <Img
                fluid={fluid}
                className={styles.aboutImageWrapper}
                alt="next adventure photo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ApplySection.propTypes = {};

export default ApplySection;
