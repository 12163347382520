import React from 'react';
import ResponsiveSlider from '../../../responsive-slider/responsive-slider';
import styles from './home-page-slider.module.scss';

export default () => {
  const slides = [
    {
      title: 'Benefits at your fingertips',
      description:
        'From guaranteed hours to employee discounts, Trustaff travelers not only have the best selection of assignments, they enjoy great benefits. Health insurance options start at just $24/week!',
      ctaButtonText: 'Traveler benefits',
      ctaButtonLink: '/nurses/salary-benefits/',
      iconClass: styles.slideIconBenefits,
    },
    {
      title: 'Top pay tailored to you',
      description:
        'Our travelers are some of the best paid professionals in the industry. Travel nurses typically earn about 20-30% more than their staff counterparts.',
      ctaButtonText: 'Pay & bonuses',
      ctaButtonLink: '/travel-nursing/pay-bonuses/',
      iconClass: styles.slideIconPay,
    },
    {
      title: 'Instant job access',
      description:
        'We partner with the nation’s top facilities. Register with us to get exclusive access to thousands of the best travel nurse assignments in your area of specialty.',
      ctaButtonText: 'Search jobs',
      ctaButtonLink: '/jobs/',
      iconClass: styles.slideIconAccess,
    },
  ];

  return <ResponsiveSlider slides={slides} />;
};
